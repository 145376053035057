.personal_info_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #f0f2f5;
  width: 100vw;
}

.about_me_div {
  width: 500px;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.plain_text {
  font-size: 1.2vw;
}

.heading {
  font-size: 2vw;
  margin-bottom: 1px;
}

.img_div {
  width: 500px;
  text-align: center;
}

.img {
  width: 200px;
}
