.main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #575555; /* color of the scrollbar thumb */
  border-radius: 5px; /* border radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a3a0a0; /* color of the scrollbar thumb on hover */
}
