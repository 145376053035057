.company_heading_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.logo_div {
  margin-top: 1px;
  margin-bottom: 0.25px;
}

.motto_div {
  margin-top: 1px;
  margin-bottom: 0;
  text-align: center;
}

.quote {
  font-size: 1.2vw;
  font-weight: 600;
  font-style: italic;
  color: #6d6b6b;
  margin-bottom: 1px;
}

.name {
  font-size: 1.5vw;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
}

.logo {
  width: 100px;
}
