.footer_div {
  background-color: #262626;
  color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding-top: 5px;
  padding-bottom: 5px;
}

.company_data_div {
  margin-left: 50px;
  width: 50vw;
  font-size: 1.2vw;
}

.copyright_div {
  margin-right: 50px;
  font-size: 0.7vw;
  transition: transform 0.5s, color 0.5s;
  cursor: pointer;
}

.copyright_div:hover {
  transform: scale(1.1);
  color: #d9251c;
}
