.services_div {
  background-color: #262626;
  color: #ffffff;
}

.specilization_heading {
  font-size: 4.4vw;
  text-align: center;
  margin-top: 1px;
  margin-bottom: 10px;
  -webkit-text-stroke: 1px #ddd4d4;
  color: transparent;
}

.services_list {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 1px;
  padding-bottom: 5px;
  width: 100vw;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.service_name {
  margin-top: 2px;
  margin-bottom: 2px;
  font-size: 1.5vw;
  font-weight: 600;
}

.img {
  width: 6vw;
}
