.company_info_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: #f0f2f5;
  width: 100vw;
}

.info {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  margin-bottom: 1px;
}

.contact_icon {
  width: 20px;
}

.contact_text {
  font-size: 1.3vw;
  margin-bottom: 1px;
  margin-top: 1px;
}
