.location_div {
  background-color: #868282;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100vw;
}

.location_heading {
  font-size: 2.2vw;
  font-style: italic;
  text-align: center;
  color: #f7f7f7;
}

.images_div {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.building_img_div {
  display: flex;
  gap: 25px;
}

.building {
  width: 250px;
  margin-bottom: 20px;
}

.map {
  width: 400px;
  cursor: pointer;
  transition: transform 0.5s;
}

.map:hover {
  transform: scale(1.05);
}
